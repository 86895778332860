import { Config } from './Config';

type JsonConfig = {
  baseUrl: string;
};

type GlobalWithConfig = {
  config?: JsonConfig;
};

interface WindowWithConfig extends Window {
  __NM_APPS_UI__?: GlobalWithConfig;
}

const DEFAULT_CONFIG: Config = new Config({
  baseUrl: 'http://0.0.0.0:3000',
});

const windowWithConfig = window as WindowWithConfig;

const ENV_CONFIG = windowWithConfig?.__NM_APPS_UI__?.config
  ? new Config(windowWithConfig.__NM_APPS_UI__.config)
  : undefined;

export const CONFIG: Config = ENV_CONFIG || DEFAULT_CONFIG;
