import { CONFIG } from 'app/configurator';

function App() {
  const sparsifyUrl = `${CONFIG.baseUrl}/sparsify`;
  return (
    <p>
      Redirecting to <a href={sparsifyUrl}>{sparsifyUrl}</a>. Click <a href={sparsifyUrl}>here</a>{' '}
      if you are not redirected automatically.
    </p>
  );
}

export default App;
