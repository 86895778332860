type ConfigConstructor = {
  baseUrl: string;
};

export class Config {
  _basePath: string;

  _baseUrl: string;

  constructor({ baseUrl }: ConfigConstructor) {
    this._baseUrl = baseUrl;

    this._basePath = new URL(this.baseUrl).pathname;
  }

  get basePath(): string {
    return this._basePath;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }
}
